<template>
  <div class="operations">
    <v-overlay :value="loading">
      <v-progress-circular indeterminate size="50"></v-progress-circular>
    </v-overlay>

    <template>
      <v-row>
        <v-col cols="2" class="mt-3">
          <v-menu
            ref="datesMenu"
            v-model="datesMenu"
            :close-on-content-click="false"
            :nudge-right="40"
            transition="scale-transition"
            offset-y
            max-width="290px"
            min-width="290px"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="datesStr"
                label="Период"
                prepend-icon="mdi-calendar-month"
                readonly
                v-bind="attrs"
                v-on="on"
                outlined
                class="textField"
              ></v-text-field>
            </template>
            <v-date-picker
              range
              no-title
              min="2019-01-01"
              type="month"
              locale="ru-Latn"
              v-if="datesMenu"
              v-model="dates"
              full-width
              @click:month="saveMonth"
            ></v-date-picker>
          </v-menu>
        </v-col>
      </v-row>
    </template>

    <div class="checkBoxes">
      <v-checkbox
        class="pa-0 ma-0"
        label="Раскрывать ячейки по одной"
        v-model="enableSingleExpand"
      ></v-checkbox>
    </div>

    <v-select
      dense
      :items="carNameForInspections"
      :label="filtersInspections.cars.length ? 'Выбранные авто' : 'Все авто'"
      v-model="filtersInspections.cars"
      class="mb-n5"
      outlined
      multiple
    ></v-select>
    <v-text-field
      v-model="register.search"
      append-icon="mdi-magnify"
      label="Поиск"
      single-line
      hide-details
    ></v-text-field>
    <v-data-table
      dense
      :headers="register.headers"
      :items="carInspections"
      :items-per-page="10"
      :search="register.search"
      @click:row="(item, slot) => slot.expand(!slot.isExpanded)"
      class="elevation-1"
      :single-expand="enableSingleExpand"
      mobile-breakpoint="0"
    >
      <template v-slot:expanded-item="{ item }">
        <td colspan="3">
          <v-carousel>
            <v-carousel-item v-for="(url, i) in item.carPhotos" :key="i" eager>
              <v-img
                @click="expandedPhoto = url"
                :style="responsiveStyleForImages"
                contain
                eager
                :src="`${url}`"
              >
                <template v-slot:placeholder>
                  <v-layout fill-height align-center justify-center ma-0>
                    <v-progress-circular
                      indeterminate
                      color="tertiary"
                    ></v-progress-circular>
                  </v-layout>
                </template>
              </v-img>
            </v-carousel-item>
          </v-carousel>
        </td>
      </template>
      <template v-slot:item.date="{ item }">
        {{ dateFormat(item.date) }}
      </template>
    </v-data-table>

    <v-overlay v-if="expandedPhoto">
      <v-img
        :src="`${expandedPhoto}`"
        contain
        :max-width="maxWidth"
        @click="expandedPhoto = null"
      >
      </v-img>
    </v-overlay>
  </div>
</template>

<script>
import moment from 'moment';
import { mapGetters } from 'vuex';
export default {
  data() {
    return {
      datesMenu: false,
      loading: false,
      expandedPhoto: null,
      enableSingleExpand: true,
      dates: [
        moment()
          .add(-1, 'month')
          .startOf('month')
          .format('YYYY-MM-DD'),
        moment()
          .endOf('month')
          .format('YYYY-MM-DD'),
      ],
      totalsTable: {
        search: '',
        headers: [
          {
            text: 'Автомобиль',
            align: 'start',
            sortable: false,
            value: 'title',
          },
          // { text: 'Доля', value: 'chunk' },
          // { text: 'Ваш доход', value: 'ownerProfit' },
          // { text: 'Выручка', value: 'proceeds' },
          // { text: 'Расходы', value: 'consumption' },
          // { text: 'Комиссия УК', value: 'comission' },
          { text: 'Дата', value: 'date' },
        ],
      },
      register: {
        search: '',
        headers: [
          // {
          //   text: 'id',
          //   align: 'start',
          //   sortable: false,
          //   value: 'id',
          // },
          // { text: 'Дата/время', value: 'dateTime' },
          // { text: 'Контрагент', value: 'counterparty' },
          { text: 'Дата', value: 'date' },
          { text: 'Автомобиль', value: 'title' },
          // { text: 'Сумма', value: 'sum' },
          // { text: 'Тип', value: 'type' },
          // { text: 'Комментарий', value: 'comment' },
        ],
      },
    };
  },
  methods: {
    dateFormat(ISOdate) {
      return moment(ISOdate).format('DD.MM.YYYY');
    },
    minDateTo(type) {
      if (type == 'dateAt') return moment('2019-01-01').format('YYYY-MM-DD');
      if (type == 'dateTo') {
        if (this.dateAt) return moment(this.dateAt).format('YYYY-MM-DD');
        else return moment().format('YYYY-MM-DD');
      }
    },
    saveMonth() {
      if (this.dates.length == 2) {
        this.datesMenu = false;
      }
    },
    async findStatistic(dates) {
      this.loading = true;
      await this.$store.dispatch('getCarInspections', dates);
      this.loading = false;
    },
    sumField(key) {
      // sum data in give key (property)
      if (this.carOperations.cars) {
        const sum = this.carOperations.cars.reduce(
          (a, b) => a + (b[key] || 0),
          0
        );
        return new Intl.NumberFormat('ru-RU').format(sum);
      }
    },
  },
  computed: {
    datesStr() {
      moment.locale('ru');
      return `${moment(this.dates[0]).format('MMMM')} - ${moment(this.dates[1])
        .endOf('month')
        .format('MMMM')}`;
    },
    responsiveStyleForImages() {
      const widthOfScreen = window.innerWidth;
      let maxW = 600;
      if (widthOfScreen < 650) maxW = window.innerWidth - 50;
      return `max-width:${maxW}px; height: 500px; margin: auto`;
    },
    maxWidth() {
      const widthOfScreen = window.innerWidth;
      let maxW = 800;
      if (widthOfScreen < 650) maxW = window.innerWidth;
      return maxW;
    },
    maxHeight() {
      const heightOfScreen = window.innerHeight;
      let maxH = 800;
      if (heightOfScreen < 650) maxH = window.innerHeight;
      return maxH;
    },
    ...mapGetters([
      'carInspections',
      'carNameForInspections',
      'filtersInspections',
    ]),
  },
  watch: {
    dates: async function(dates) {
      if (dates.length != 2) return;
      await this.findStatistic(dates);
    },
  },
  async mounted() {
    await this.findStatistic(this.dates);
  },
};
</script>

<style lang="scss" scoped>
.textField {
  min-width: 230px;
}
</style>
