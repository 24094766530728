var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"operations"},[_c('v-overlay',{attrs:{"value":_vm.loading}},[_c('v-progress-circular',{attrs:{"indeterminate":"","size":"50"}})],1),[_c('v-row',[_c('v-col',{staticClass:"mt-3",attrs:{"cols":"2"}},[_c('v-menu',{ref:"datesMenu",attrs:{"close-on-content-click":false,"nudge-right":40,"transition":"scale-transition","offset-y":"","max-width":"290px","min-width":"290px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({staticClass:"textField",attrs:{"label":"Период","prepend-icon":"mdi-calendar-month","readonly":"","outlined":""},model:{value:(_vm.datesStr),callback:function ($$v) {_vm.datesStr=$$v},expression:"datesStr"}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.datesMenu),callback:function ($$v) {_vm.datesMenu=$$v},expression:"datesMenu"}},[(_vm.datesMenu)?_c('v-date-picker',{attrs:{"range":"","no-title":"","min":"2019-01-01","type":"month","locale":"ru-Latn","full-width":""},on:{"click:month":_vm.saveMonth},model:{value:(_vm.dates),callback:function ($$v) {_vm.dates=$$v},expression:"dates"}}):_vm._e()],1)],1)],1)],_c('div',{staticClass:"checkBoxes"},[_c('v-checkbox',{staticClass:"pa-0 ma-0",attrs:{"label":"Раскрывать ячейки по одной"},model:{value:(_vm.enableSingleExpand),callback:function ($$v) {_vm.enableSingleExpand=$$v},expression:"enableSingleExpand"}})],1),_c('v-select',{staticClass:"mb-n5",attrs:{"dense":"","items":_vm.carNameForInspections,"label":_vm.filtersInspections.cars.length ? 'Выбранные авто' : 'Все авто',"outlined":"","multiple":""},model:{value:(_vm.filtersInspections.cars),callback:function ($$v) {_vm.$set(_vm.filtersInspections, "cars", $$v)},expression:"filtersInspections.cars"}}),_c('v-text-field',{attrs:{"append-icon":"mdi-magnify","label":"Поиск","single-line":"","hide-details":""},model:{value:(_vm.register.search),callback:function ($$v) {_vm.$set(_vm.register, "search", $$v)},expression:"register.search"}}),_c('v-data-table',{staticClass:"elevation-1",attrs:{"dense":"","headers":_vm.register.headers,"items":_vm.carInspections,"items-per-page":10,"search":_vm.register.search,"single-expand":_vm.enableSingleExpand,"mobile-breakpoint":"0"},on:{"click:row":function (item, slot) { return slot.expand(!slot.isExpanded); }},scopedSlots:_vm._u([{key:"expanded-item",fn:function(ref){
var item = ref.item;
return [_c('td',{attrs:{"colspan":"3"}},[_c('v-carousel',_vm._l((item.carPhotos),function(url,i){return _c('v-carousel-item',{key:i,attrs:{"eager":""}},[_c('v-img',{style:(_vm.responsiveStyleForImages),attrs:{"contain":"","eager":"","src":("" + url)},on:{"click":function($event){_vm.expandedPhoto = url}},scopedSlots:_vm._u([{key:"placeholder",fn:function(){return [_c('v-layout',{attrs:{"fill-height":"","align-center":"","justify-center":"","ma-0":""}},[_c('v-progress-circular',{attrs:{"indeterminate":"","color":"tertiary"}})],1)]},proxy:true}],null,true)})],1)}),1)],1)]}},{key:"item.date",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.dateFormat(item.date))+" ")]}}])}),(_vm.expandedPhoto)?_c('v-overlay',[_c('v-img',{attrs:{"src":("" + _vm.expandedPhoto),"contain":"","max-width":_vm.maxWidth},on:{"click":function($event){_vm.expandedPhoto = null}}})],1):_vm._e()],2)}
var staticRenderFns = []

export { render, staticRenderFns }